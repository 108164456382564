import { handleActions } from 'redux-actions';

const defaultState = {
  billingManagement: {
    current: 1,
    pageSize: 10,
    companyName: '',
    orderId: '',
    billingInformationStatus: undefined,
  },
  OrderTracking: {
    current: 1,
    pageSize: 10,
    companyName: '',
    orderId: '',
    productName: '',
  },
};

export const toolsOptions = handleActions(
  {
    set_billingManagement_req: (state, action) => {
      const newParams = { ...state.billingManagement, ...action.payload };
      return { ...state, billingManagement: newParams };
    },
    //价格跟踪
    set_OrderTracking_req: (state, action) => {
      const newParams = { ...state.OrderTracking, ...action.payload };
      return { ...state, OrderTracking: newParams };
    },
  },
  defaultState
);
