import { handleActions } from "redux-actions";

const defaultFilesListData = {
  // 商品管理
  MainImagefileList: [], //主图
  DetailsImagesfileList: [], //细节图
  MSDSfileList: [], //MSDS
  ROHSfileList: [], //ROHS

  // 旗舰店管理
  CoverfileList: [], //封面
  PhotoAlbumfileList: [] //相册
};
export const FilesListData = handleActions(
  {
    // 商品管理
    MAINIMAGEFILELIST: (state, action) => ({
      ...state,
      MainImagefileList: action.payload.data
    }),
    //细节图
    DETAILSIMAGESFILELIST: (state, action) => ({
      ...state,
      DetailsImagesfileList: action.payload.data
    }),
    //主图
    MSDSFILELIST: (state, action) => ({
      ...state,
      MSDSfileList: action.payload.data
    }),
    ROHSFILELIST: (state, action) => ({
      ...state,
      ROHSfileList: action.payload.data
    }),

    // 旗舰店管理
    COVERFILELIST: (state, action) => ({
      ...state,
      CoverfileList: action.payload.data
    }),
    PHOTOALBUMFILELIST: (state, action) => ({
      ...state,
      PhotoAlbumfileList: action.payload.data
    })
  },
  defaultFilesListData
);
