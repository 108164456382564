import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
// import thunk from 'react-thunk';
import rootReducer from './redux/reducers'; //reducer
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import App from './App';
import zhCN from 'antd/lib/locale/zh_CN';
import 'element-theme-default';
import './assets/css/reset.css';
import './assets/css/common.scss';
import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';

const store = createStore(rootReducer);
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'http://a7531536b8b44a728f32551e94c58d1f@172.16.8.18:19000/3',
    autoSessionTracking: true,
    integrations: [
      new SentryRRWeb({
        // default is empty
        checkoutEveryNth: 100,
        // default is 5 minutes
        checkoutEveryNms: 15 * 60 * 1000,
        // on by default
        maskAllInputs: false
      })
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App/>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
