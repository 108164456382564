import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb, Menu, Dropdown, Avatar, message } from 'antd';
import next from '../assets/images/next.png';
import ChangePassword from '../components/ChangePassword';
import { logout } from '../api/userModule';
import Cookies from 'js-cookie';
import routeArr from '../router/LoyoutRoutes';

const LHeader = (props) => {
  const [BreadcrumbArr, setBreadcrumbArr] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);

  const userLayout = () => {
    logout({ id: props.userAndMenuData.userData.systemUser.userId }).then(
      (res) => {
        if (res.code === 200) {
          message.success(res.data);
          Cookies.remove('Authentication');
          props.history.push('/login');
        } else {
          message.error(res.msg);
        }
      }
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key={'edit'} onClick={() => setModalStatus(true)}>
        修改密码
      </Menu.Item>
      <Menu.Item key={'layout'} onClick={() => userLayout()}>
        退出
      </Menu.Item>
    </Menu>
  );

  //获取Bread
  const getBreadArr = useCallback(() => {
    if (!props.location.state) return;
    routeArr.forEach((item) => {
      if (item.Bread && item.Bread.length > 0) {
        if (item.path === props.location.state.path) {
          setBreadcrumbArr(item.Bread);
        }
      } else {
        if (item.path === props.location.state.path) {
          setBreadcrumbArr([]);
        }
      }
    });
  }, [props.location.state]);

  useEffect(() => {
    getBreadArr();
  }, [getBreadArr]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '52px',
        lineHeight: '52px',
        padding: '0 33px 0 10px',
      }}
    >
      {/*面包屑导航 */}
      <Breadcrumb
        separator="|"
        style={{
          fontSize: '14px',
          color: '#5C5C5C',
          fontWeight: 300,
        }}
      >
        {BreadcrumbArr.map((item, index) => (
          <Breadcrumb.Item key={index}>
            {item.path ? (
              <Link to={{ pathname: item.path, state: { path: item.path } }}>
                {item.name}
              </Link>
            ) : (
              <span>{item.name}</span>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <div
        style={{
          height: '100%',
        }}
      >
        {/*下拉菜单 */}
        <Dropdown overlay={menu} placement="bottomCenter">
          <div
            style={{
              padding: 0,
              border: 0,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              paddingBottom: '10px',
            }}
          >
            <Avatar
              src={props.userAndMenuData.userData.systemUser.avatar}
              shape="circle"
              size={32}
            />
            <span
              style={{
                fontSize: '14px',
                height: '14px',
                lineHeight: '14px',
                fontWeight: 300,
                color: '#403B3B',
                display: 'inline-block',
                marginRight: '10px',
              }}
            >
              {props.userAndMenuData.userData.systemUser.userName}
            </span>
            <img src={next} alt="" style={{ marginBottom: '2px' }} />
          </div>
        </Dropdown>
        {/*密码修改 */}
        <ChangePassword
          modalStatus={modalStatus}
          setModalStatus={() => setModalStatus()}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userAndMenuData: state.userAndMenuData,
  };
}

export default connect(mapStateToProps)(withRouter(LHeader));
