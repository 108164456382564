import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import routeArr from "../router/LoyoutRoutes";
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
// import lazy from "../router/Lazy";

const ContentRouter = () => {
  return (
    <div className="mainModule">
      {routeArr.map((item) => (
        <Suspense key={item.path} fallback={<div className='flex-center-center' style={{ height: '200px' }}><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}/></div>}>
          <Route
            exact
            path={item.path}
            component={item.RouteComponent}
          />
        </Suspense>
      ))}
    </div>
  );
};

export default ContentRouter;
