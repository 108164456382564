import { handleActions } from "redux-actions";

const defaultData = {
  goodsData: {
    id: "", // 编辑时用到
    productName: "", //商品名称
    productCategoryId: [], // 品类
    productCategoryIdIII: [], // 品类三级
    brandName: "",
    Specification: [{ value: undefined }],
    ColorNumber: [{ value: undefined }],
    Series: [{ value: undefined }],
    SpecialSpecification: [{ value: undefined }],
    placeOfOrigin: "",
    service: "",
    CombinedData: [],
    unit: "",
    shippingTemplatesId: "",
    MainImage: [], //主图
    DetailsImages: [], //细节图
    introduction: undefined,
    stockMin:1, // 非集采起订数量
    //集采需要的字段
    groupBuyTimeStart: "", // 集采开始时间
    groupBuyTimeEnd: "", // 集采结束时间
    groupBuyStock: "", // 数量上限
    groupBuyDescription: "", // 集采说明
    isHot: '', // 是否热卖s
    isSpecialPrice: '', // 是否特价
    groupBuyStockMin: 0, // 集采库存 起订数量
    productBaseInfo:'', // 选择映射商品
  },
  steps: 0,
  isCombine: false,
  isCollect: false, // 是不是集采商品的添加/编辑   ture: 添加/编辑集采商品  false:添加/编辑普通商品
  pageStatus: "add", // 是新增还是编辑状态 add:新增  edit：编辑
  ReqOptions: {
    tabKey: "2", // tabKey
    current: 1,
    pageSize: 10,
    CommodityStatus: false, //商品状态 (1)true:上架 TabKey === "1" || TabKey === "4"   (2) false：下架 TabKey === "2" || TabKey === "3"
    brandName: "",
    productName: "",
    storeId: null,
    productCode: "",
    marketingType: 0,
  },
};
export const commodityReduxData = handleActions(
  {
    goodsData: (state, action) => {
      return { ...state, goodsData: action.payload.data };
    },
    steps: (state, action) => {
      return { ...state, steps: action.payload.data };
    },
    isCombine: (state, action) => {
      return { ...state, isCombine: action.payload.data };
    },
    // 是不是集采商品的添加/编辑
    isCollect: (state, action) => {
      return { ...state, isCollect: action.payload.data };
    },
    // 是不是集采商品的添加/编辑
    setPageStatus: (state, action) => {
      return { ...state, pageStatus: action.payload.data };
    },
    setReqOptions: (state, action) => {
      return { ...state, ReqOptions: action.payload.data };
    },
  },
  defaultData
);
