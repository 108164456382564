import React from "react";
import BasicRoute from "./router/index.jsx";

const App = () => {
  return (
    <div>
      <BasicRoute />
    </div>
  );
};

export default App;
