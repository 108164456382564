import { handleActions } from 'redux-actions';

const defaultState = {
  reqParams: {
    current: 1,
    pageSize: 10,
    companyName: '',
    orderId: '',
    isExceedPrice: undefined, // 是否跌超保证金
    field: null, // 需要排序的字段名
    sortOrder: undefined, // 升序还是降序 ascend：升序 descend：降序
    statusList: '10'
  },
  counterData : {
    state: 'add',
    amount: '',
    amount2: '',
    amountDays: '',
    counterData:'',
    contractCode: "",
    contractTime: "",
    createTime: '',
    days: '',
    days2: '',
    deadlineTime: '',
    deliveryTime: '',
    depositPayTime: '',
    handlingCharges: '',
    id: '',
    isDelete: '',
    occupyCapital: '',
    orderComputerItemVOList: [],
    orderId: "",
    quantity: '',
    rate: '',
    rate2: '',
    totalExpense: '',
    updateTime: '',
    warehouseAddress: '',
    warehouseCharges: '',
    warehousingActualDays: '',
    warehousingTime: '',
    sunPrice:'',
    sunTotalPrice:''
  },
  // 库存推送跳转页面State
  inventoryData: {
    state:'add',
  },
};

export const DunningMaintenance = handleActions(
  {
    set_params_value: (state, action) => {
      const newParams = { ...state.reqParams, ...action.payload };
      return { ...state, reqParams: newParams };
    },
    SetCounterData: (state, action) => {
      const newParams = { ...state.reqParams, ...action.payload };
      return { ...state, counterData: newParams };
    },
    setInventoryData:(state, action) => ({
      ...state,
      inventoryData: action.data,
    }),
  },
  defaultState
);
