import { lazy } from 'react';

const routeArr = [
  // 广告栏
  {
    path: '/tycm/AdColumn',
    RouteComponent: lazy(() => import('../views/AdColumn')),
    Bread: [
      {
        name: '广告栏',
        path: '/tycm/AdColumn',
      },
    ],
  },
  //品类管理
  {
    path: '/tycm/Channel',
    RouteComponent: lazy(() => import('../views/Channel')),
    Bread: [
      {
        name: '品类管理',
        path: '/tycm/Channel',
      },
    ],
  },
  //优惠券管理
  {
    path: '/tycm/Coupon',
    RouteComponent: lazy(() => import('../views/Coupon')),
    Bread: [
      {
        name: '营销管理',
        path: '',
      },
      {
        name: '优惠券管理',
        path: '/tycm/Coupon',
      },
    ],
  },
  //库存处置
  {
    path: '/tycm/StockDisposal',
    RouteComponent: lazy(() => import('../views/StockDisposal/index')),
    Bread: [
      {
        name: '营销管理',
        path: '',
      },
      {
        name: '库存处置',
        path: '/tycm/StockDisposal',
      },
    ],
  },
  //试料登记
  {
    path: '/tycm/SampleRegistration/Sample',
    RouteComponent: lazy(() => import('../views/SampleRegistration/Sample')),
    Bread: [
      {
        name: '试料登记',
        path: '/tycm/SampleRegistration/Sample',
      },
    ],
  },
  {
    path: '/tycm/LnquiryQuotation',
    RouteComponent: lazy(() => import('../views/LnquiryQuotation/index')),
    Bread: [
      {
        name: '报价维护',
        path: '',
      },
    ],
  },
  //积分管理
  {
    path: '/tycm/PointsManagement',
    RouteComponent: lazy(() => import('../views/PointsManagement/index')),
    Bread: [
      {
        name: '积分管理',
        path: '',
      },
    ],
  },
   //分销管理
   {
    path: '/tycm/Distribution',
    RouteComponent: lazy(() => import('../views/Distribution/index')),
    Bread: [
      {
        name: '分销管理',
        path: '',
      },
    ],
  },
  //分销详情
  {
    path: '/tycm/Distribution/:orderId',
    RouteComponent: lazy(() => import('../views/Distribution/distributionDetail')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '分销订单详情',
        path: '',
      },
    ],
  },
  // 订单
  //商品管理
  {
    path: '/tycm/Commodity',
    RouteComponent: lazy(() => import('../views/Commodity/index')),
    Bread: [
      {
        name: '商品管理',
        path: '/tycm/Commodity',
      },
    ],
  },
  //商品添加
  {
    path: '/tycm/Commodity/add',
    RouteComponent: lazy(() => import('../views/Commodity/add')),
    Bread: [
      {
        name: '商品列表',
        path: '/tycm/Commodity',
      },
    ],
  },
  //店铺管理-旗舰店列表
  {
    path: '/tycm/Store/Flagship',
    RouteComponent: lazy(() => import('../views/Store/FlagshipStore')),
    Bread: [
      {
        name: '旗舰店管理',
        path: '/tycm/Store/Flagship',
      },
    ],
  },
  //店铺管理-超级旗舰店
  {
    path: '/tycm/Store/SuperStore',
    RouteComponent: lazy(() => import('../views/Store/superStore/index')),
    Bread: [
      {
        name: '超级旗舰店',
        path: '/tycm/Store/SuperStore',
      },
    ],
  },
  //店铺管理-旗舰店列表详情
  {
    path: '/tycm/StoreDetailPage',
    RouteComponent: lazy(() =>
      import('../components/FlagshipStore/StoreDetailPage')
    ),
    Bread: [
      {
        name: '旗舰店管理',
        path: '/tycm/Store/Flagship',
      },
      {
        name: '店铺详情',
        path: '/tycm/StoreDetailPage',
      },
    ],
  },
  //订单管理-订单列表
  {
    path: '/tycm/order/orderList',
    RouteComponent: lazy(() => import('../views/Order/Order')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '订单列表',
        path: '/tycm/order/orderList',
      },
    ],
  },
  //订单审核
  {
    path: '/tycm/order/Audit',
    RouteComponent: lazy(() => import('../views/Order/Audit')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '订单审核',
        path: '/tycm/order/Audit',
      },
    ],
  },
  //邮件发送页面
  // {
  //   path: '/tycm/EmailPage',
  //   RouteComponent: lazy(() => import('../views/Order/EmailPage')),
  //   Bread: [
  //     {
  //       name: '订单管理',
  //       path: '',
  //     },
  //     {
  //       name: '发送邮件',
  //       path: '/tycm/EmailPage',
  //     },
  //   ],
  // },
  //订单管理-回收站
  {
    path: '/tycm/order/OrderListRecycleBin',
    RouteComponent: lazy(() => import('../views/Order/OrderListRecycleBin')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '回收站',
        path: '/tycm/order/OrderListRecycleBin',
      },
    ],
  },
  // 订单详情
  {
    path: '/tycm/OrderDetails/:orderId',
    RouteComponent: lazy(() => import('../views/Order/OrderDetails')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '订单详情',
        path: '',
      },
    ],
  },
  // 订单详情-合同细则
  {
    path: '/tycm/Order/Contract',
    RouteComponent: lazy(() => import('../views/Order/contract')),
    Bread: [
      {
        name: '订单审核',
        path: '/tycm/order/Audit',
      },
      {
        name: '合同细则',
        path: '',
      },
    ],
  },
  //板棒材合同汇总 -- 合同细则
  {
    path: '/tycm/order/ContractBBC',
    RouteComponent: lazy(() => import('../views/Order/ContractBBC')),
    Bread: [
      {
        name: '订单审核',
        path: '/tycm/order/Audit',
      },
      {
        name: '合同细则',
        path: '',
      },
    ],
  },
  // 订单详情-合同细则 （合同模板三件套/第二步）
  {
    path: '/tycm/Order/Contract/stepTwo',
    RouteComponent: lazy(() => import('../views/Order/contractStepTwo')),
    Bread: [
      {
        name: '订单审核',
        path: '/tycm/order/Audit',
      },
      {
        name: '合同三件套',
        path: '',
      },
    ],
  },
  // 订单详情-合同细则 （合同模板三件套/第三步）
  {
    path: '/tycm/Order/Contract/stepThree',
    RouteComponent: lazy(() => import('../views/Order/contractStepThree')),
    Bread: [
      {
        name: '订单审核',
        path: '/tycm/order/Audit',
      },
      {
        name: '合同三件套',
        path: '',
      },
    ],
  },
  //合同管理
  {
    path: '/tycm/order/ContractManagement',
    RouteComponent: lazy(() => import('../views/Order/ContractManagement')),
    Bread: [
      {
        name: '合同',
        path: '',
      },
      {
        name: '合同管理',
        path: '/tycm/order/ContractManagement',
      },
    ],
  },
  //合同汇总
  {
    path: '/tycm/order/ContractSummary',
    RouteComponent: lazy(() => import('../views/Order/ContractSummary')),
    Bread: [
      {
        name: '合同',
        path: '',
      },
      {
        name: '订单合同',
        path: '/tycm/order/ContractSummary',
      },
    ],
  },
  //合同汇总 -- 合同详情
  {
    path: '/tycm/order/ContractSummaryDetail',
    RouteComponent: lazy(() => import('../views/Order/ContractSummaryDetail')),
    Bread: [
      {
        name: '订单合同',
        path: '/tycm/order/ContractSummary',
      },
      {
        name: '合同详情',
        path: '/tycm/order/ContractSummaryDetail',
      },
    ],
  },
  //认证管理
  {
    path: '/tycm/CertificationManagement',
    RouteComponent: lazy(() => import('../views/Certification')),
    Bread: [
      {
        name: '认证管理',
        path: '/tycm/CertificationManagement',
      },
    ],
  },
  //代客下单
  {
    path: '/tycm/OrderBehalfCustomers',
    RouteComponent: lazy(() => import('../views/OrderBehalfCustomers')),
    Bread: [
      {
        name: '辅助管理',
        path: '/tycm/OrderBehalfCustomers',
      },
    ],
  },

  //角色管理
  {
    path: '/tycm/RoleManagement',
    RouteComponent: lazy(() => import('../views/SystemSet/Role')),
    Bread: [
      {
        name: '系统设置',
        path: '',
      },
      {
        name: '角色管理',
        path: '/tycm/RoleManagement',
      },
    ],
  },
  //后台用户管理
  {
    path: '/tycm/UserManagement',
    RouteComponent: lazy(() => import('../views/SystemSet/User')),
    Bread: [
      {
        name: '系统设置',
        path: '',
      },
      {
        name: '后台用户管理',
        path: '/tycm/UserManagement',
      },
    ],
  },
  //权限管理
  {
    path: '/tycm/AuthorityManagement',
    RouteComponent: lazy(() => import('../views/SystemSet/Authority')),
    Bread: [
      {
        name: '系统设置',
        path: '',
      },
      {
        name: '权限管理',
        path: '/tycm/AuthorityManagement',
      },
    ],
  },
  //销助权限
  {
    path: '/tycm/SalesAssistance',
    RouteComponent: lazy(() => import('../views/SystemSet/Assistance')),
    Bread: [
      {
        name: '系统设置',
        path: '',
      },
      {
        name: '销助权限',
        path: '/tycm/SalesAssistance',
      },
    ],
  },
  //订单管理-开票管理
  {
    path: '/tycm/order/BillingManagement',
    RouteComponent: lazy(() => import('../views/Order/BillingManagement')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '开票管理',
        path: '/tycm/order/BillingManagement',
      },
    ],
  },
  //订单管理-订单跟踪
  {
    path: '/tycm/order/OrderTracking',
    RouteComponent: lazy(() => import('../views/Order/OrderTracking/index')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '订单跟踪',
        path: '/tycm/order/OrderTracking',
      },
    ],
  },
  //订单管理-开票管理-申请开票
  {
    path: '/tycm/order/ApplyBilling/:orderId',
    RouteComponent: lazy(() => import('../views/Order/ApplyBilling')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '开票管理',
        path: '/tycm/order/BillingManagement',
      },
      {
        name: '申请开票',
        path: '',
      },
    ],
  },
  //订单管理-开票管理-开票详情
  {
    path: '/tycm/order/BillingDetails/:orderId',
    RouteComponent: lazy(() => import('../views/Order/ApplyBilling')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '开票管理',
        path: '/tycm/order/BillingManagement',
      },
      {
        name: '开票详情',
        path: '',
      },
    ],
  },
  //运费模板
  {
    path: '/tycm/FreightTemplate',
    RouteComponent: lazy(() => import('../views/FreightTemplate')),
    Bread: [
      {
        name: '运费模板',
        path: '/tycm/FreightTemplate',
      },
    ],
  },
  //自提点管理
  {
    path: '/tycm/PickupPointManagement',
    RouteComponent: lazy(() => import('../views/PickupPointManagement')),
    Bread: [
      {
        name: '自提点管理',
        path: '/tycm/PickupPointManagement',
      },
    ],
  },
  //支付管理付款条件管理
  {
    path: '/tycm/PaymentManagement/PayTerms',
    RouteComponent: lazy(() => import('../views/PaymentManagement/PayTerms')),
    Bread: [
      {
        name: '支付管理',
        path: '',
      },
      {
        name: '支付方式管理',
        path: '/tycm/PaymentManagement/PayTerms',
      },
    ],
  },
  //用户管理
  {
    path: '/tycm/UserManagementPage',
    RouteComponent: lazy(() => import('../views/UserManagementPage')),
    Bread: [
      {
        name: '用户管理',
        path: '/tycm/UserManagementPage',
      },
    ],
  },
  {
    path: '/tycm/UserManagementPage/UserDetailPage',
    RouteComponent: lazy(() =>
      import('../components/UserManagementPage/UserDetailPage')
    ),
    Bread: [
      {
        name: '用户管理',
        path: '',
      },
      {
        name: '详情',
        path: '/tycm/UserManagementPage/UserDetailPage',
      },
    ],
  },
  //订单统计
  {
    path: '/tycm/OrderStatistics',
    RouteComponent: lazy(() => import('../views/OrderStatistics')),
    Bread: [
      {
        name: '订单统计',
        path: '/tycm/OrderStatistics',
      },
    ],
  },
  //资讯管理
  {
    path: '/tycm/NewsPage',
    RouteComponent: lazy(() => import('../views/NewsPage')),
    Bread: [
      {
        name: '资讯管理',
        path: '/tycm/NewsPage',
      },
    ],
  },
  //操作日志
  {
    path: '/tycm/OperationLog',
    RouteComponent: lazy(() => import('../views/OperationLog')),
    Bread: [
      {
        name: '系统设置',
        path: '',
      },
      {
        name: '操作日志',
        path: '/tycm/OperationLog',
      },
    ],
  },
  //品牌管理
  {
    path: '/tycm/BrandManagement',
    RouteComponent: lazy(() => import('../views/BrandManagement')),
    Bread: [
      {
        name: '品牌管理',
        path: '/tycm/BrandManagement',
      },
    ],
  },
  //订单管理-集采订单
  {
    path: '/tycm/order/CollectOrder',
    RouteComponent: lazy(() => import('../views/Order/CollectOrder')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '集采订单',
        path: '/tycm/order/CollectOrder',
      },
    ],
  },
  //集采详情
  {
    path: '/tycm/CollectOrderDetails/:orderId',
    RouteComponent: lazy(() => import('../views/Order/CollectOrderDetails')),
    Bread: [
      {
        name: '订单管理',
        path: '',
      },
      {
        name: '集采订单',
        path: '/tycm/order/CollectOrder',
      },
      {
        name: '集采详情',
        path: '',
      },
    ],
  },
  //报价管理
  {
    path: '/tycm/QuotationManagement',
    RouteComponent: lazy(() => import('../views/QuotationManagement')),
    Bread: [
      {
        name: '报价管理',
        path: '/tycm/QuotationManagement',
      },
    ],
  },
  //采购管理
  {
    path: '/tycm/PurchaseManagement',
    RouteComponent: lazy(() => import('../views/PurchaseManagement')),
    Bread: [
      {
        name: '采购管理',
        path: '/tycm/PurchaseManagement',
      },
    ],
  },
  //微信信息管理
  {
    path: '/tycm/WxMsg',
    RouteComponent: lazy(() => import('../views/WxMsg/index')),
    Bread: [
      {
        name: '微信信息管理',
        path: '/tycm/WxMsg',
      },
    ],
  },
  //微信关键词管理
  {
    path: '/tycm/WxKeywords',
    RouteComponent: lazy(() => import('../views/WxKeywords/index')),
    Bread: [
      {
        name: '微信关键词管理',
        path: '/tycm/WxKeywords',
      },
    ],
  },
  //店铺信息
  {
    path: '/tycm/StoreInformation',
    RouteComponent: lazy(() => import('../views/StoreInformation/index')),
    Bread: [
      {
        name: '店铺信息',
        path: '',
      },
    ],
  },
  //催款维护
  {
    path: '/tycm/DunningMaintenance',
    RouteComponent: lazy(() => import('../views/DunningMaintenance/index')),
    Bread: [
      {
        name: '催款维护',
        path: '',
      },
    ],
  },
  //邮件发送页面
  {
    path: '/tycm/EmailPage',
    RouteComponent: lazy(() => import('../views/EmailPage')),
    Bread: [
      {
        name: '催款维护',
        path: '/tycm/DunningMaintenance',
      },
      {
        name: '发送催款函',
        path: '',
      },
    ],
  },
  //后台首页
  {
    path: '/tycm/HomePage',
    RouteComponent: lazy(() => import('../views/HomePage/index')),
    Bread: [
      {
        name: '首页',
        path: '',
      },
    ],
  },
  //跟单列表页面
  {
    path: '/tycm/Documentary',
    RouteComponent: lazy(() => import('../views/Documentary/index')),
    Bread: [
      {
        name: '跟单列表',
        path: '',
      },
    ],
  },
  {
    path: '/tycm/Documentary/add',
    RouteComponent: lazy(() => import('../views/Documentary/add')),
    Bread: [
      {
        name: '跟单计算器',
        path: '',
      },
    ],
  },
  // 库存推送
  {
    path: '/tycm/Inventory',
    RouteComponent: lazy(() => import('../views/Inventory/index')),
    Bread: [
      {
        name: '库存推送',
        path: '',
      },
    ],
  },
  {
    path: '/tycm/Inventory/add',
    RouteComponent: lazy(() => import('../views/Inventory/add')),
    Bread: [
      {
        name: '库存推送',
        path: '',
      },
    ],
  },
  // 每日推送
  {
    path: '/tycm/DailyPush',
    RouteComponent: lazy(() => import('../views/DailyPush/index')),
    Bread: [
      {
        name: '每日推送',
        path: '',
      },
    ],
  },
  //成本核算页面
  {
    path: '/tycm/Costaccount',
    RouteComponent: lazy(() => import('../views/Costaccount/index')),
    Bread: [
      {
        name: '成本核算',
        path: '',
      },
    ],
  },
  {
    path: '/tycm/Costaccount/add',
    RouteComponent: lazy(() => import('../views/Costaccount/add')),
    Bread: [
      {
        name: '成本核算',
        path: '',
      },
    ],
  },
];

export default routeArr;
