import { handleActions } from 'redux-actions';

const defaultData = {
  orderReqState: {
    current: 1,
    pageSize: 10,
    status: '', //订单状态
    orderId: '',
    companyName: '',
    productName: '',
    salesmanName:'',
    reviewStartTime: '',
    reviewEndTime: '',
    contractStatus: '',
  },
  AuditReqState: {
    current: 1,
    pageSize: 10,
    status: '0', //订单状态
    orderId: '',
    companyName: '',
    productName: '',
    salesmanName:'',
    reviewStartTime: '',
    reviewEndTime: '',
    contractStatus: '',
  },
  orderListTableData: {
    orderTableData: [],
    total: [],
    isLoading: false,
  },
  dateTime: '', //预计交货日期
  contractDate: '', //代入合同交货时间
  SigningInformationData: [], // 签收信息数据
  SigningInformationDataSelect: [], // 选中的签收信息key
  setSearchParms: { //查询参数
    orderId : '',
    companyName : '',
    erpOrderCode : '',
    current: ''
  },
};
export const orderRedux = handleActions(
  {
    orderListState: (state, action) => {
      return { ...state, orderReqState: action.payload.data };
    },
    AuditReqState: (state, action) => {
      return { ...state, AuditReqState: action.payload.data };
    },
    orderListTableData: (state, action) => {
      return { ...state, orderListTableData: action.payload.data };
    },
    DATETIME: (state, action) => ({
      ...state,
      dateTime: action.data,
    }),
    CONTRACTDATE: (state, action) => ({
      ...state,
      contractDate: action.data,
    }),
    setSigningInformationData: (state, action) => ({
      ...state,
      SigningInformationData: action.data,
    }),
    setSigningInformationDataSelect: (state, action) => ({
      ...state,
      SigningInformationDataSelect: action.data,
    }),
    SETSEARCHPARMS: (state,action) => ({
      ...state,
      setSearchParms: action.data,
    })
  },
  defaultData
);
