import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setUserData } from '../redux/actions/loginAndsider';
import { menuByUser, currentUserData } from '../api/userModule';
import { withRouter } from 'react-router-dom';
import { Layout, Affix, message } from 'antd';
import SiderNav from './SiderNav';
import LHeader from './LHeader';
import ContentRouter from './ContentRouter';

const { Header, Content } = Layout;

const LayoutIndex = (props) => {
  const getMenuByUser = () => {
    menuByUser().then((res) => {
      if (res.code === 200) {
        sessionStorage.setItem('menuArr', JSON.stringify(res.data));
      } else {
        sessionStorage.setItem('menuArr', JSON.stringify([]));
        message.error(res.msg);
      }
    });
  };
  /**
   * 获取当前用户信息
   *
   */
  const getCurrentUserData = () => {
    currentUserData().then(async (res) => {
      if (res.code === 200) {
        await props.dispatch(
          setUserData({
            type: 'SETUSERDATA',
            data: res.data,
          })
        );
        getMenuByUser();
      } else if (res.code === 407) {
        props.history.push('/login');
      } else {
        message.error(res.msg);
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [props.location.pathname]);
  useEffect(() => {
    getCurrentUserData();
  }, []);
  return (
    <div>
      <Layout style={{ minHeight: '100vh',overflow: "hidden" }}>
        <div>
          <SiderNav/>
        </div>
        <Layout className="site-layout">
          <Affix offsetTop={0}>
            <Header
              className="site-layout-background"
              style={{ padding: 0, background: '#fff', height: '52px' }}
            >
              <LHeader />
            </Header>
          </Affix>
          <Content style={{ background: '#F7F7F7', display: 'flex' }}>
            <ContentRouter />
          </Content>
          {/* <Affix offsetBottom={0}>
            <Footer style={{ textAlign: "center" }}>
              Ant Design ©2018 Created by Ant UED
            </Footer>
          </Affix> */}
        </Layout>
      </Layout>
    </div>
  );
};

export default connect()(withRouter(LayoutIndex));
