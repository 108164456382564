import { handleActions } from "redux-actions";

const defaultModalData = {
  commonVisible: false,
  CommonModalName: "add",
  CommonBackData: {},
  ChannelModData: {
    id: 0,
    name: "",
    density: "",
    parentId: 0,
    sort: ""
  }, // 品类管理回显数据
  CommoditylModData: {}, // 商品管理回显数据
  CommoditylId: "", // 商品id
  FlagshipModData: {}, // 旗舰店管理回显数据
  PickupPointModData: {}, // 自提点理回显数据
  SalesModData:{}, //小猪权限
  UserMangeModData: {},
  UserId: '',
  ModalMaintainData:[],//维护报价数据
  CouponModData: {},//编辑优惠券回显数据
  FreightTemplateModData: {
    appoint: true,
    appointInfo: [
      {
        num: "1",
        place: [{ cityId: "0", name: "所有区域" }],
        price: "8",
        freeType: 1
      }
    ],
    createTime: "2020-12-29 14:01:56",
    name: "",
    regionInfo: [
      {
        continuePrice: "1",
        continues: "1",
        first: "1",
        price: "1",
        region: [{ cityId: "0", name: "所有区域" }],
        key: 0
      }
    ],
    sort: 0,
    type: 1,
    updateTime: null,
    templateType: 2
  }, // 运费模板回显数据
  SystemUserModData: {}, // 后台用户管理
  price:'',
  service:''
};
export const modalData = handleActions(
  {
    // 公共弹框visible
    COMMONVISIBLE: (state, action) => ({
      ...state,
      commonVisible: action.payload.status
    }),
    // 维护报价数据
    MODALMAINTAINDATA: (state, action) => ({
      ...state,
      ModalMaintainData: action.payload.data
    }),
    // 公共弹框名
    COMMONMODALNAME: (state, action) => ({
      ...state,
      CommonModalName: action.payload.name
    }),
    //回显数据
    COMMONBACKDATA: (state, action) => ({
      ...state,
      CommonBackData: action.payload.data
    }),
    // 品类
    SETCHANNELMODDATA: (state, action) => ({
      ...state,
      ChannelModData: action.payload.data
    }),
    //  商品管理
    SETCOMMODITYLMODDATA: (state, action) => ({
      ...state,
      CommoditylModData: action.payload.data
    }),
    COMMODITYLID: (state, action) => ({
      ...state,
      CommoditylId: action.payload.data
    }),
    // 旗舰店管理
    FLAGSHIPMODDATA: (state, action) => ({
      ...state,
      FlagshipModData: action.payload.data
    }),
    // 自提点管理
    PICKUPPOINTMODDATA: (state, action) => ({
      ...state,
      PickupPointModData: action.payload.data
    }),
    // 小猪权限
    SALESMODDATA: (state, action) => ({
      ...state,
      SalesModData: action.payload.data
    }),
    // 用户管理
    USERMANGEMODDATA: (state, action) => ({
      ...state,
      UserMangeModData: action.data
    }),
    USERID: (state, action) => ({
      ...state,
      UserId: action.payload.data
    }),

    // 优惠券管理
    COUPONMODDATA: (state, action) => ({
      ...state,
      CouponModData: action.payload.data
    }),
    // 微信管理
    WXKEYWORDMODDATA: (state, action) => ({
      ...state,
      WxkeyWordModData: action.payload.data
    }),
    //运费模板
    FREIGHTTEMPLATEMODDATA: (state, action) => ({
      ...state,
      FreightTemplateModData: action.payload.data
    }),
    //后台用户管理
    SYSTEMUSERMODDATA: (state, action) => ({
      ...state,
      SystemUserModData: action.payload.data
    }),
    //询报价报价维护价格
    INQIRYPRICE: (state, action) => ({
      ...state,
      InquiryPrice: action.payload.data
    }),
    //询报价报价维护服务费
    INQIRYSERVICE: (state, action) => ({
      ...state,
      InquiryService: action.payload.data
    })
  },
  defaultModalData
);
