import axios from 'axios';
import Cookies from 'js-cookie';
let baseURL = process.env.REACT_APP_URL;
const service = axios.create({
  baseURL: baseURL,
  withCredentials: false // 允许携带cookie
});

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    config.headers["Authentication"] = Cookies.get("Authentication")
      ? Cookies.get("Authentication")
      : '';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  response => {
    if(response.data.code == 407){
      Cookies.remove('Authentication');
      Cookies.set('loginState', response.data.msg)
      window.location.href= '/login'
    }
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);
export default service;
