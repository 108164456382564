import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import '../assets/css/Login.scss';
import logo from '../assets/logo.png';
import welcome from '../assets/images/welcome.png';
import eyeClose from '../assets/images/eye-close.png';
import eyeOpen from '../assets/images/eye-open.png';
import { useSelector } from 'react-redux';
import { userLogin, menuByUser, currentUserData } from '../api/userModule';
import Cookies from 'js-cookie';
import { setUserData } from '../redux/actions/loginAndsider';

const Login = (props) => {
  const [form] = Form.useForm();
  const userInfo = useSelector((state) => state.userAndMenuData.userData)
  const loginState = Cookies.get('loginState')
  useEffect(()=>{
    if(loginState){
      message.error(loginState)
      Cookies.remove('loginState')
    }
  },[loginState])
  const onClickLogin = async () => {
    try {
      const values = await form.validateFields();
      doLogin(values);
      // console.log("Success:", values);
    } catch (errorInfo) {
      // console.log("Failed:", errorInfo);
    }
  };

  const token = Cookies.get('Authentication');
  /**
   * 登录
   *
   * @param {*} values 账号密码
   */
  const doLogin = (values) => {
    userLogin(values).then((res) => {
      if (res.code === 200) {
        Cookies.set('Authentication', res.data.token, { expires: 1 });
        getCurrentUserData();
        getMenuByUser();
      } else {
        message.error(res.msg);
      }
    });
  };

  /**
   *  获取菜单列表
   *
   */
  const getMenuByUser = () => {
    menuByUser().then(async (res) => {
      if (res.code === 200) {
        message.success('登录成功');
        sessionStorage.setItem('menuArr', JSON.stringify(res.data));
        props.history.push({
          pathname: userInfo.systemUser.storeId === 1 ? '/tycm/HomePage' : '/tycm/order/Audit',
          state: {
            path: userInfo.systemUser.storeId === 1 ? '/tycm/HomePage' : '/tycm/order/Audit',
          },
        });
      } else {
        sessionStorage.setItem('menuArr', JSON.stringify([]));
        message.error(res.msg);
      }
    });
  };

  /**
   * 获取当前用户信息
   *
   */
  const getCurrentUserData = () => {
    currentUserData().then(async (res) => {
      if (res.code === 200) {
        await props.dispatch(
          setUserData({
            type: 'SETUSERDATA',
            data: res.data,
          })
        );
      }
    });
  };

  const isLogined = useCallback(() => {
    currentUserData().then(async (res) => {
      if (res.code === 200) {
        await props.dispatch(
          setUserData({
            type: 'SETUSERDATA',
            data: res.data,
          })
        );
        await menuByUser().then(async (res) => {
          if (res.code === 200) {
            sessionStorage.setItem('menuArr', JSON.stringify(res.data));
            props.history.push({
              pathname: userInfo.systemUser.storeId === 1 ? '/tycm/HomePage' : '/tycm/order/Audit',
              state: {
                path: userInfo.systemUser.storeId === 1 ? '/tycm/HomePage' : '/tycm/order/Audit',
              },
            });
          } else {
            sessionStorage.setItem('menuArr', JSON.stringify([]));
          }
        });
      }
    });
  }, [props]);

  useEffect(() => {
    if(token){
      isLogined();
    }
  }, [isLogined]);

  return (
    <div className="Login">
      <main className="login-box flex-center-center">
        <section className="login-left">
          <img src={logo} alt="" className="logo"/>
        </section>
        <div className="login-content">
          <img src={welcome} alt="" className="login-content-welcome"/>
          <h2 className="login-content-title">
            登录 <i className="login-content-title-underline"/>
          </h2>
          <Form form={form} name="normal_login" className="login-form">
            <h5 className="labelH">账号</h5>
            <Form.Item
              name="userName"
              rules={[
                { required: true, message: '请输入手机号码或邮箱!' },
                () => ({
                  validator (rule, value) {
                    if (!value || /^1[345789]\d{9}$/.test(value) || /^[A-Za-z0-9._%-]+@([A-Za-z0-9-]+.)+[A-Za-z]{2,4}$/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject('请输入正确的手机号码或邮箱！');
                  },
                }),
              ]}
            >
              <Input placeholder="请输入手机号码或邮箱" className="login-userName"/>
            </Form.Item>
            <h5 className="labelH">密码</h5>
            <Form.Item
              name="password"
              value=""
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password
                placeholder="请输入密码"
                iconRender={(visible) =>
                  visible ? (
                    <img src={eyeOpen} alt=""/>
                  ) : (
                    <img src={eyeClose} alt=""/>
                  )
                }
              />
            </Form.Item>
            <div className="btnBox">
              {/* <p className="LoginErrorText">账号或密码错误，请重新输入！</p> */}
              <Form.Item style={{ marginBottom: '0' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="userModalBtn"
                  onClick={() => onClickLogin()}
                >
                  登录
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </main>
    </div>
  );
};

export default connect()(withRouter(Login));
