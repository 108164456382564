import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Form, Input, Button, message } from "antd";
import { updataPass, logout } from "../api/userModule";
import Cookies from "js-cookie";

const ChangePassword = props => {
  let [form] = Form.useForm();

  const userLayout = () => {
    logout({ id: props.userAndMenuData.userData.systemUser.userId }).then(
      res => {
        console.log(res);
        if (res.code === 200) {
          message.success(res.data);
          Cookies.remove("Authentication");
          props.setModalStatus(false);
          props.history.push("/login");
        } else {
          message.error(res.msg);
        }
      }
    );
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      updataPass({
        password: values.password
      }).then(res => {
        if (res.code === 200) {
          userLayout();
        } else {
          message.error(res.msg);
        }
      });
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleCancel = () => {
    props.setModalStatus(false);
  };

  return (
    <div>
      <Modal
        title="修改密码"
        visible={props.modalStatus}
        centered
        footer={false}
        destroyOnClose
        maskClosable={false}
        onOk={e => handleOk(e)}
        onCancel={e => handleCancel(e)}
        getContainer
      >
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          labelAlign="right"
          labelCol={{ span: 4 }}
        >
          {/* <Form.Item
            name="current_pass"
            label="当前密码"
            rules={[{ required: true, message: "请输入当前密码" }]}
          >
            <Input placeholder="请输入当前密码" />
          </Form.Item> */}

          <Form.Item
            name="password"
            label="新密码"
            rules={[{
              required: true, message: "新密码包含字母、数字、和特殊符号且8到12位!" ,pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\W]{8,12}$/,
            }]}
          >
            <Input type="password" placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="确认密码"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "请再次输入新密码!"
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("两次输入的密码不一样!");
                }
              })
            ]}
          >
            <Input type="password" placeholder="请再次输入新密码" />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                className="tyys-modal-btn-primary"
                type="primary"
                htmlType="submit"
                onClick={() => handleOk()}
              >
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userAndMenuData: state.userAndMenuData
  };
}
export default connect(mapStateToProps)(withRouter(ChangePassword));
