import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu, Affix, message } from 'antd';
import logo from '../assets/logo.png';
import onlyLogo from '../assets/images/onlyLogo.png';
import { menuByUser } from '../api/userModule';
import '../assets/css/nav_icon.css';
import '../assets/css/siderNav.scss';
import * as AllIcon from '@ant-design/icons';
import Icon from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SiderNav = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [defaultSelectedKeys, setdefaultSelectedKeys] = useState([]);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const [menuArr, setMenuArr] = useState(
    sessionStorage.getItem('menuArr')
      ? JSON.parse(sessionStorage.getItem('menuArr'))
      : []
  );

  const getMenuByUser = () => {
    menuByUser().then((res) => {
      if (res.code === 200) {
        sessionStorage.setItem('menuArr', JSON.stringify(res.data));
        setMenuArr(res.data);
        setDefaultKey(res.data);
      } else {
        sessionStorage.setItem('menuArr', JSON.stringify([]));
        message.error(res.msg);
      }
    });
  };

  // 设置defaultSelectKey
  const setDefaultKey = (value) => {
    value.forEach((item) => {
      if (item.path === props.location.pathname) {
        setdefaultSelectedKeys([item.id]);
      }
      if (item.children && item.children.length > 0) {
        setDefaultKey(item.children);
      }
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem('menuArr')) {
      var tempMenuArr = JSON.parse(sessionStorage.getItem('menuArr'));
      setMenuArr(tempMenuArr);
      setDefaultKey(tempMenuArr);
    } else {
      getMenuByUser();
    }
  }, [props.location.pathname]);

  const openPage = (path) => {
    props.history.push({
      pathname: path,
      state: {
        path: path,
      },
    });
  };
  const rootSubmenuKeys = [];
  menuArr.map(item=>{
    rootSubmenuKeys.push(item.id)
  })
  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div className="SiderNav">
      <Affix offsetTop={0}>
        <div>
          <Sider
            theme="dark"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
          >
            <div className="logo" style={{ borderBottom: '1px solid #223e6b' }}>
              {collapsed ? (
                <img src={onlyLogo} alt="" className="onlyLogo" />
              ) : (
                <img src={logo} alt="" />
              )}
            </div>
            <Menu
              theme="dark"
              selectedKeys={defaultSelectedKeys}
              mode="inline"
              className="SiderNav-ul"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
            >
              {menuArr.map((item) => {
                if (item.children && item.children.length > 0) {
                  return (
                    <SubMenu
                      key={item.id}
                      icon={<Icon component={AllIcon[item.icon]} />}
                      title={item.name}
                    >
                      {item.children.map((val) => (
                        <Menu.Item key={val.id} className="child-item-style" onClick={() => openPage(val.path)}>
                          <span>{val.name}</span>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item
                      key={item.id}
                      className="only-item-style"
                      icon={<Icon component={AllIcon[item.icon]} />}
                      onClick={() => openPage(item.path)}
                    >
                      <span> {item.name}</span>
                    </Menu.Item>
                  );
                }
              })}
            </Menu>
          </Sider>
        </div>
      </Affix>
    </div>
  );
};

export default withRouter(SiderNav);
