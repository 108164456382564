import { combineReducers } from "redux";
import * as todoList from "./todoList"; //as别名
import * as loginAndsider from "./loginAndsider";
import * as todoModal from "./todoModal";
import * as setFileList from "./setFileList";
import * as commodityReduxData from "./commodity";
import * as ValetOrder from "./ValetOrder";
import * as order from "./order";
import * as DunningMaintenance from "./DunningMaintenance";
import * as toolsOptions from "./toolsOptions";

const rootReducer = combineReducers({
  ...todoList,
  ...loginAndsider,
  ...todoModal,
  ...setFileList,
  ...commodityReduxData,
  ...ValetOrder,
  ...order,
  ...DunningMaintenance,
  ...toolsOptions,
});

export default rootReducer;
