import { handleActions } from 'redux-actions';
import { filterDate } from '../../utils/filters';

const today = filterDate(new Date().getTime());
const defaultData = {
  storeData: {
    storeName: '同益云商',
    storeId: 1,
  },
  ProductList: [],
  copyProductBool:false,
  weiXinNickName: '',
  mobile: '',
  contractData: {
    type: 1, //合同模板类型
    buyName: null, //买方
    buyerMobile: null, //买方电话
    buyerContact: null, //买方联系人
    buyerAddress: null, //买方地址
    DateSigning: today, //签订日期
    deliveryMethod: 1, //交提方式
    deliveryTime: today, //交货时间
    orderId: null,
    paymentMethod: null, //收款条件
    salesman: null, //卖方联系人
    deliveryAddress: null, //交货地址
    unifiedSocialCreditCode: null, //统一信用代码
    email: null, //企业邮箱
  },
  CreateOrder: {},
  companyEmail: undefined,
  erpdata:null
};
export const ValetOrderData = handleActions(
  {
    storeData: (state, action) => ({
      ...state,
      storeData: action.payload.data,
    }),
    ProductList: (state, action) => ({
      ...state,
      ProductList: action.payload.data,
    }),
    copyProductBool: (state, action) => ({
      ...state,
      copyProductBool: action.payload.data,
    }),
    contractData: (state, action) => ({
      ...state,
      contractData: action.payload.data,
    }),
    WEIXINNICKNAME: (state, action) => ({
      ...state,
      weiXinNickName: action.payload.data,
    }),
    MOBILE: (state, action) => ({
      ...state,
      mobile: action.payload.data,
    }),
    setCompanyEmail: (state, action) => ({
      ...state,
      companyEmail: action.payload.data,
    }),
    setErpData: (state, action) => ({
      ...state,
      erpData: action.payload.data,
    }),
  },
  defaultData
);
