import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LayoutIndex from "../layout/index";
import Login from "../views/Login";
import { useSelector } from "react-redux";

const BasicRoute = () => {
  const userInfo = useSelector((state) => state.userAndMenuData.userData)
  return (
    <Router>
      <Switch>
        <Route path="/tycm" component={LayoutIndex} />
        <Route path="/login" component={Login} />
        <Redirect from="/" to={userInfo.systemUser.storeId === 1 ? '/tycm/HomePage' : '/tycm/order/Audit'} />
      </Switch>
    </Router>
  );
};

export default connect()(BasicRoute);
