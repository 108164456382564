export const filterNum = (data) => {
  try {
    let tempString = data.toString().trim();
    let fixedTwo = (Number(tempString) * 100) / 100;
    fixedTwo = fixedTwo.toFixed(2);
    let res = (fixedTwo + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    return res.toString().trim();
  }
  catch (error) {
    // console.log(error);
  }
};

export const ThousandsNum = (num) => {
  try {
    var res = num.toString().replace(/\d+/, function (n) { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
          return $1 + ",";
      });
    })
    return res;
  }
  catch (error) {
    // console.log(error);
  }
};

/**
 * 改进版千分数过滤器 （小数位进行了四舍五入）
 * @param value {string|number} 需要过滤的数
 * @param options {{currency: string, style: string}} currency:币种 CNY:人民币;EUR：欧元;USD:美元  style: currency: 货币格式, decimal:纯数字格式;percent:百分比格式;
 * @returns {string}
 * @constructor
 */
export const ThousandsFilter = (value, options) => {
  let tempString = value.toString().trim();
  const obj = { ...options };
  // 保留两位小数
  obj.minimumFractionDigits = 2;
  obj.maximumFractionDigits = 2;
  const handleNum = Number(tempString).toLocaleString("zh", obj);
  return `${handleNum}`;
};
// 保留六位小数
export const ThousandsSixFilter = (value, options) => {
  let tempString = value.toString().trim();
  const obj = { ...options };
  // 保留两位小数
  obj.minimumFractionDigits = 0;
  obj.maximumFractionDigits = 6;
  const handleNum = Number(tempString).toLocaleString("zh", obj);
  return `${handleNum}`;
};

// 千分数四舍五入
export const GroupiesNum = () => {
  return (data) => {
    if (data) {
      let fixedTwo = data.toFixed(2);
      return (fixedTwo + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    }
    else {
      return "-";
    }
  };
};
export const filterCurrency = () => {
  return (data) => {
    switch (data) {
      case "人民币":
        return "¥";
      case "港币":
        return "HK$";
      case "美元":
        return "$";
      default:
        break;
    }
  };
};
// 月
export const getMonth = () => {
  return (data) => {
    let newData = new Date(data);
    return newData.getMonth() + 1;
  };
};
// 年月日
export const filterDate = (data) => {
  let newData = new Date(data);
  const format = (num) => {
    return num < 10 ? "0" + num : num;
  };
  const year = newData.getFullYear();
  const month = format(newData.getMonth() + 1);
  const day = format(newData.getDate());
  return `${year}-${month}-${day}`;
};

// 年月日时分秒
export const filterDateTime = (data) => {
  let newData = new Date(data);
  const format = (num) => {
    return num < 10 ? "0" + num : num;
  };
  const year = newData.getFullYear();
  const month = format(newData.getMonth() + 1);
  const day = format(newData.getDate());
  const hour = format(newData.getHours());
  const Minutes = format(newData.getMinutes());
  const Seconds = format(newData.getSeconds());
  return `${year}-${month}-${day} ${hour}:${Minutes}:${Seconds}`;
};

// 付款条件过滤
export const filterPaymentTerms = (paymentTerms) => {
  switch (paymentTerms) {
    case 1:
      return "微信支付";
    case 2:
      return "支付宝";
    case 3:
      return "公司转账";
    case 4:
      return "月结支付";
    default:
      break;
  }
};

// 月结条件过滤
export const filterPayMode = (filterPayMode) => {
  switch (filterPayMode) {
    case 1:
      return "月结";
    case 2:
      return "货到月结";

    default:
      break;
  }
};

// 订单状态过滤
export const filterStatus = (status) => {
  switch (status) {
    case 0:
      return "待确认";
    case 5:
      return "送签中";
    case 10:
      return "待付款";
    case 30:
      return "待发货";
    case 50:
      return "待收货";
    case 100:
      return "已完成";
    case 500:
      return "订单关闭";
    case 510:
      return "已取消";
    default:
      break;
  }
};

// 开票状态
export const filterBillingStatus = (status) => {
  switch (status) {
    case 0:
      return "未开票";
    case 1:
      return "申请中";
    case 2:
      return "已开票";

    default:
      break;
  }
};

// 组装预览图数据
export const handleFileBackData = (data) => {
  if (data) {
    let hArr = data.split(",");
    let Cdata = [];
    hArr.forEach((element) => {
      let obj = {
        status: "finished",
        url: element,
        name: element.replace(/(.*\/)*([^.]+).*/gi, "$2"),
        response: {
          code: 200,
          data: [element],
        },
      };
      Cdata.push(obj);
    });
    return Cdata;
  }
};

//店铺状态过滤 状态 0:待审核 1:审核通过 2:未通过 3:关闭
export const filterStoreState = (status) => {
  switch (Number(status)) {
    case 0:
      return "待审核";
    case 1:
      return "审核通过";
    case 2:
      return "未通过";
    case 3:
      return "已关闭";

    default:
      break;
  }
};

//优惠券管理状态 1：已上线 ，0:已下线
export const CouponManagementState = (status) => {
  switch (Number(status)) {
    case 1:
      return "已上线";
    case 0:
      return "已下线";
    default:
      break;
  }
};
//优惠卷类型1：服务费
export const CouponTypeState = (status) => {
  switch (Number(status)) {
    case 1:
      return "服务费";
    case 2:
    return "商品";
    default:
      break;
  }
};
//支付条件
export const PayTermState = (status) => {
  switch (Number(status)) {
    case 0:
      return "票到付款";
    case 1:
      return "微信支付";
    case 2:
      return "支付宝";
    case 3:
      return "公司转账";
    case 4:
      return "月结支付";
    case 5:
      return "微众银行";
    case 6:
      return "平安银行";
    default:
      break;
  }
};
//推广渠道 1：定向投放

export const PromotionChannels = (status) => {
  switch (Number(status)) {
    case 1:
    return "全部投放";
    case 2:
    return "定向投放（客户）";
    case 3:
    return "首张订单（客户）";
    case 4:
    return "扫码领取";
    default:
      break;
  }
};

//微信关键词类型1：供应 2：求购 3:屏蔽关键词
export const WechatKeywordType = (status) => {
  switch (Number(status)) {
    case 1:
      return '供应';
    case 2:
      return '求购';
    case 3:
      return '屏蔽关键词';
    default:
      break;
  }
};
