import { handleActions } from 'redux-actions';

const defaultData = {
  userData: {
    systemUser: { avatar: '', userName: '', userId: '' },
    permissions: [],
  },
};
export const userAndMenuData = handleActions(
  {
    SETUSERDATA: (state, action) => {
      return { ...state, userData: action.payload.data };
    },
  },
  defaultData
);
