import request from "../utils/request";

// 获取分页
export function userLogin(data) {
  return request({
    url: `/login`,
    method: "POST",
    data
  });
}

// 根据用户查询菜单路由
export function menuByUser(data) {
  return request({
    url: `/menu/byUser`,
    method: "GET",
    params: data
  });
}

// 获取当前用户
export function currentUserData(data) {
  return request({
    url: `/current`,
    method: "GET",
    params: data
  });
}

// 退出
export function logout(data) {
  return request({
    url: `/logout/${data.id}`,
    method: "GET"
  });
}

// 修改密码
export function updataPass(data) {
  return request({
    url: `/update-password`,
    method: "PUT",
    data
  });
}

// 查询后台人员类型
export function userType() {
  return request({
    url: `/system-user/type`,
    method: "GET",
  });
}

// 云商客户同步erp客户信息接口
export function refresh(data) {
  return request({
    url: `/erp/user-refresh`,
    method: "POST",
    data
  });
}
